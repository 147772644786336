import { IonImg } from "@ionic/react";
import "./CategoryItem.scss";

const CategoryItem = ({img='assets/imgs/hamburguer.svg', name, className = "default"}) => {
    return (
        <div id="category_item" className={className}>
            <div className="category_img_container">
                <IonImg src={img} />
            </div>
            <h3>{name}</h3>
        </div>
    )
}
export default CategoryItem;

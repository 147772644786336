import { IonContent, IonItem, IonList, IonPage, IonTextarea } from "@ionic/react"
import { cash, chevronForwardOutline, location, pricetag } from "ionicons/icons";
import { useState } from "react";
import { useHistory } from "react-router";
import HeaderCondense from "../../components/common/header-condense/HeaderCondense"
import Header from "../../components/common/header/Header"
import ItemDetailIcon from "../../components/common/item-detail-icon/ItemDetailIcon";
import Spacer from "../../components/common/spacer/Spacer";
import ResponsiveContainer from "../../components/layout/responsive-container/ResponsiveContainer";
import Map from "../../components/map/Map"
import { METHOD, TIME } from "../../const/chekoutOptions";
import CartStore from "../../store/CartStore";
import OrderProduct from "../order/components/order-product/OrderProduct";
import './CheckoutScreen.scss';
import CheckoutFooter from "./components/cheackout-footer/CheckoutFooter";
import DeliveryTimeModal from "./components/delivery-time-modal/DeliveryTimeModal";
import OptionSelect from "./components/option-select/OptionSelect";
import PromoModal from "./components/promo-modal/PromoModal";
import SectionTitle from "./components/section-title/SectionTitle";
import Summary from "./components/summary/Summary";

const CheckoutScreen = () => {
  const items = CartStore.useState(s => s.items);
  const [text, setText] = useState();
  const [showPromoModal, setShowPromoModal] = useState(false);
  const [showDeliveryTimeModalModal, setShowDeliveryTimeModalModal] = useState(false);
  const history = useHistory();

    const goToLocationManagerScreen = () => {
        history.push('/location');
    }

  const onTimeOptionChange = (value) => {
    setShowDeliveryTimeModalModal(value.label === 'Programar');
  }

    return (
      <IonPage mode="ios" id="checkout_screen">
        <Header title="Pago" backButton={true} />
        <IonContent fullscreen>
          <HeaderCondense title="Restaurant" />
          <ResponsiveContainer>
            <SectionTitle title="Metodo de entrega" />
            <OptionSelect values={METHOD} />

            <div className="map_container">
              <Map origin={{ lat: 14.7073446, lng: -91.8526478 }} />
            </div>

            <ItemDetailIcon lines="full" onClick={goToLocationManagerScreen} title="Coatepeque, Quetzaltenango" subtitle="Barrio El Jardín" icon={location} detailIcon={chevronForwardOutline} />

            <SectionTitle title="Tiempo de entrega estimado" subtitle="30-40 min" />
            <OptionSelect className="column" values={TIME} onChange={onTimeOptionChange} />

            <SectionTitle title="Tus productos" button="Agregar más" />

            <IonList>
              {items.map(item => (
                <OrderProduct key={item.id} {...item} />
              ))}
            </IonList>

            <ItemDetailIcon onClick={() => setShowPromoModal(true)} title="Agregar código promo" lines="full" icon={pricetag} detailIcon={chevronForwardOutline} />

            <div className="wala-content">
              <IonItem mode="md" className="wala-input">
                <IonTextarea value={text} placeholder="Agregar una nota para el restaurante" onIonChange={e => setText(e.detail.value)} />
              </IonItem>

              <Summary />


            </div>
            <SectionTitle title="Método de pago" />
            <ItemDetailIcon title="Efectivo" icon={cash} iconColor="success"/>
                <span className="disclaimer">Si no estás presente, el socio repartidor dejará el pedido en la puerta. Al realizar tu pedido, aceptas asumir toda la responsabilidad una vez que se realice la entrega.</span>
            <Spacer />
          </ResponsiveContainer>
        </IonContent>
        <CheckoutFooter grandTotal="132.00" />
        <PromoModal showModal={showPromoModal} setShowModal={setShowPromoModal} />
        <DeliveryTimeModal showModal={showDeliveryTimeModalModal} setShowModal={setShowDeliveryTimeModalModal} />
      </IonPage>
    )
}

export default CheckoutScreen

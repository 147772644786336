import { IonSkeletonText } from '@ionic/react';
import './OrderStatusIndicator.scss';

const OrderStatusIndicator = ({status}) => {
    return (
        <div id="order_status_indicator">
            <IonSkeletonText animated 
            className={
                (status === 'ORDER_RECEIVED' || 
                status === 'BEING_PREPARED' || 
                status === 'ON_THE_WAY' ||
                status === 'DELIVERED') && 'current' } />
            <IonSkeletonText animated className={
                (status === 'BEING_PREPARED' || 
                status === 'ON_THE_WAY' ||
                status === 'DELIVERED') && 'current' }/>
            <IonSkeletonText animated className={ 
                (status === 'ON_THE_WAY' ||
                status === 'DELIVERED') && 'current' }/>
            <IonSkeletonText animated className={
                status === 'DELIVERED' && 'current' }/>
        </div>
    )
}

export default OrderStatusIndicator

import { IonButton, IonImg } from '@ionic/react';
import './OrderImageCover.scss';

const OrderImageCover = () => {
    return (
        <div id="order_image_cover">
                <IonImg src="assets/imgs/pollocampero.webp" />
                <IonButton mode="md" fill="solid" color="medium" shape="round" onClick={e => {
                    e.stopPropagation(); 
                }}>
                    Ver tienda
                </IonButton>
        </div>
    )
}

export default OrderImageCover

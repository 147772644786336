import { IonContent, IonPage} from '@ionic/react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import CategoryTitle from '../../components/common/category-title/CategoryTitle';
import SearchInput from '../../components/common/search-input/SearchInput';
import WalaLogo from '../../components/common/wala-logo/WalaLogo';
import ResponsiveContainer from '../../components/layout/responsive-container/ResponsiveContainer';
import CategoryItemCarousel from './components/category-item-carousel/CategoryItemCarousel';
import HomeHeader from './components/home-header/HomeHeader';
import LocationSelector from './components/location-selector/LocationSelector';
import PromotionalBannerCarousel from './components/promotional-banner-carousel/PromotionalBannerCarousel';
import StoreCarousel from './components/store-carousel/StoreCarousel';
import StoreList from './components/store-list/StoreList';

const HomeScreen = () => {
  const history = useHistory();
  
  const goToBrowseScreen = () => {
    history.push('/browse');
  }

  return (
    <IonPage>
      <HomeHeader>
        <LocationSelector />
         <WalaLogo/>
      </HomeHeader>

      <IonContent>
        {/* <SearchInput
          placeholder="¿Qué quieres comer?"
          onIonFocus={goToBrowseScreen}
        /> */}
        
        <CategoryItemCarousel />

        <PromotionalBannerCarousel />

        <ResponsiveContainer className="wala-content" sizeMd="12" offsetMd="0" sizeLg="12" offsetLg="0" sizeXl="12" offsetXl="0">
          

          <CategoryTitle title="Recientes" onClick={goToBrowseScreen} />
          <StoreList />

          <CategoryTitle title="Populares" onClick={goToBrowseScreen} />
          <StoreCarousel />

          </ResponsiveContainer>
      </IonContent>
      
    </IonPage>
  );
};

export default HomeScreen;

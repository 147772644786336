import { useIonViewWillEnter } from '@ionic/react';
import { useState } from 'react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import PromotionalBanner from '../promotional-banner/PromotionalBanner';
import { banners } from './banner-mock-data';
import 'swiper/components/pagination/pagination.scss';
import './PromotionalBannerCarousel.scss';

const swiperProperties = {
    slidesPerView: 1,
    pagination:true,
    autoplay: true,
    breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: 8
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        // when window width is >= 800px
        800: {
          slidesPerView: 3,
          spaceBetween: 50
        }
    }
}

// install Swiper modules
SwiperCore.use([Pagination, Autoplay]);

const PromotionalBannerCarousel = () => {

    const [ spaceBetween, setSpaceBetween ] = useState(0);
    useIonViewWillEnter(() => {
		setSpaceBetween(8);
	});

    return (
        <Swiper
        className="promotional_banner_carousel"
        spaceBetween={spaceBetween}
        {...swiperProperties}>
            {banners.map((banner, index) => (
            <SwiperSlide key={index}>
                <PromotionalBanner key={index} {...banner}/>
            </SwiperSlide>
            ))}        
      </Swiper>
    )
}

export default PromotionalBannerCarousel;

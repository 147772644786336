export const METHOD = [
    {
        label: 'Entrega a domicilio',
        selected: true
    },
    {
        label: 'Recoger en tienda',
        selected: false
    }
]

export const TIME = [
    {
        label: 'Ahora mismo',
        selected: true
    },
    {
        label: 'Programar',
        selected: false
    }
]
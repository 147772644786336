import { IonCol, IonContent, IonPage } from '@ionic/react';
import HeaderCondense from '../../components/common/header-condense/HeaderCondense';
import Header from '../../components/common/header/Header';
import ResponsiveContainer from '../../components/layout/responsive-container/ResponsiveContainer';
import OrderItem from './components/order-item/OrderItem';

const OrdersScreen = () => {
  return (
    <IonPage mode="ios">
      <Header title="Ordenes" />
      <IonContent fullscreen>
        <HeaderCondense title="Ordenes" />
      <ResponsiveContainer>
      {[1,1,1,1,1,1,1,1].map(e => (
        <IonCol size="12">
          <OrderItem />
        </IonCol>

      ))}

      </ResponsiveContainer>

      </IonContent>
    </IonPage>
  );
};

export default OrdersScreen;

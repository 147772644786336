import { IonBackButton, IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar } from "@ionic/react"
import { close} from "ionicons/icons"
import './Header.scss';

const Header = ({children=null, className="",title, defaultHref="/", backButton=false, closeButton=false, onClick=()=>{}}) => {
    return (
        <IonHeader mode="ios" id="wala_header">
        <IonToolbar mode="md" className={className}>
            <IonButtons slot="start">
               {backButton && <IonBackButton  defaultHref={defaultHref} />} 
               {closeButton && <IonButton color="light" fill="solid" shape="round" onClick={onClick}> <IonIcon slot="icon-only" color="dark" icon={close} />  </IonButton>} 
            </IonButtons>
          <IonTitle>{title}</IonTitle>
          {children}
        </IonToolbar>
      </IonHeader>
    )
}

export default Header

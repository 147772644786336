import { IonContent, IonPage } from '@ionic/react'
import HeaderCondense from '../../components/common/header-condense/HeaderCondense'
import Header from '../../components/common/header/Header'
import PlaceItem from './components/place-item/PlaceItem'

const LocationManagerScreen = () => {
    return (
        <IonPage mode="ios">
            <Header backButton={true} title="Entregar en" />
            <IonContent fullscreen>
                <HeaderCondense title="Entregar en" />
                <PlaceItem />
            </IonContent>
            
        </IonPage>
    )
}

export default LocationManagerScreen

import { IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import './ItemHeaderInfo.scss';

const ItemHeaderInfo = ({name, description}) => {
    return (
        <div id="item_header_info">
            <IonHeader  className="item_header_info_title" collapse="condense">
                <IonToolbar>
                    <h3 size="large">{name}</h3>
                </IonToolbar>
            </IonHeader>
            <div className="item_header_info_description">
            <p>{description}</p>
            </div>
        </div>
    )
}

export default ItemHeaderInfo

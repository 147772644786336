import { IonButton, IonFooter } from '@ionic/react';
import { useHistory } from 'react-router';
import './CartBottomSheetFooter.scss';

const CartBottomSheetFooter = ({setShowModal}) => {
    const history = useHistory();

    const goToStoreScreen = () => {
        history.push('/store');
        setShowModal(false);
    }

    const goToCheckoutScreen = () => {
        history.push('/checkout');
        setShowModal(false);
    }
    return (
        <IonFooter id="cart_bottom_sheet_footer" mode="ios">
            <IonButton onClick={goToCheckoutScreen} mode="md" expand="full" shape="round" fill="solid" color="success">
                Ir a pago
            </IonButton>
            <IonButton onClick={goToStoreScreen} mode="md" expand="full" shape="round" fill="solid" color="medium">
                Agregar más productos
            </IonButton>
        </IonFooter>
    )
}

export default CartBottomSheetFooter

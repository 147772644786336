import { IonSkeletonText } from "@ionic/react"
import './CategoryItemSkeleton.scss';

const CategoryItemSkeleton = () => {
    return (
        <div className="category_item_skeleton">
              <IonSkeletonText className="category_item_skeleton_image" animated />
              <IonSkeletonText className="category_item_skeleton_text" animated />
        </div>
    )
}

export default CategoryItemSkeleton

import { IonAvatar, IonContent, IonIcon, IonImg, IonItem, IonLabel, IonList, IonNote, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import ItemDetailIcon from '../../components/common/item-detail-icon/ItemDetailIcon';
import { accountOptions } from './accountOptions';
import './AccountScreen.scss';

const AccountScreen = () => {
  return (
    <IonPage id="account_screen">
      <IonContent>

        <IonList>
        <IonItem lines="full" button detailIcon="none">
          <IonAvatar slot="start">
            <IonImg src="assets/imgs/profile.png" />
          </IonAvatar>
          <ion-label>
            <h2>Anthony Cifuentes</h2>
           <IonNote color="success">Ver cuenta</IonNote>
          </ion-label>
        </IonItem>

          {accountOptions.map((opt, index) => (
          <ItemDetailIcon key={index} {...opt} />
          ))}
        </IonList>

      </IonContent>
    </IonPage>
  );
};

export default AccountScreen;

import { IonIcon } from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import { useHistory } from 'react-router';
import './LocationSelector.scss';

const LocationSelector = ({onClick = null }) => {

    const history = useHistory();

    const goToLocationManagerScreen = () => {
        history.push('/location');
    }

    return (
        <div id="location_selector" onClick={goToLocationManagerScreen}>
            <span>Entregar ahora</span>
            <p>Coatepeque, Quetzaltenango <IonIcon icon={chevronDownOutline} color="dark" /></p>
        </div>
    )
}

export default LocationSelector

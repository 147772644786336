import { IonContent, IonIcon, IonItem, IonLabel, IonPage } from "@ionic/react"
import { person, receipt } from "ionicons/icons"
import Header from "../../components/common/header/Header"
import OrderHeaderInfo from "./components/order-header-info/OrderHeaderInfo"
import OrderImageCover from "./components/order-image-cover/OrderImageCover"

const OrderScreen = () => {
    return (
        <IonPage>
            <Header title="Orden #A1f44" backButton defaultHref="/orders" />
            <IonContent>
                <OrderImageCover />
                <OrderHeaderInfo />
                <IonItem detailIcon="none">
                    <IonIcon icon={person} slot="start" color="dark" />
                    <IonLabel>Entregado por Josue Donis</IonLabel>
                </IonItem>
                <IonItem detailIcon="none">
                    <IonIcon icon={receipt} slot="start" color="dark" />
                    <IonLabel>Total: GTQ 58.00</IonLabel>
                </IonItem>
            </IonContent>
        </IonPage>
    )
}

export default OrderScreen

import { IonButton, IonImg } from '@ionic/react';
import { useHistory } from 'react-router';
import './OrderItem.scss';

const OrderItem = () => {

    const history = useHistory();

    const goToOrderScreen = () => {
        history.push('orders/order')
    }

    const goToStoreScreen = () => {
        history.push('/store');
    }

    return (
        <div id="order_item" onClick={goToOrderScreen}>
            <div className="order_item_image">
                <IonImg src="assets/imgs/pollocampero.webp"  />
            </div>
            <div className="order_item_description">
                <h3>Restaurante</h3>
                <p>1 item - Q12.00</p>
                <span>04 Ago - Completed</span>
            </div>
            <IonButton size="small" mode="md" shape="round" color="medium" onClick={(e) => {
                e.stopPropagation();
                goToStoreScreen();
            }}>
                Menu
            </IonButton>
        </div>
    )
}

export default OrderItem

import { IonBackButton, IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar } from '@ionic/react';
import { shareOutline } from 'ionicons/icons';
import './ItemHeader.scss';

const ItemHeader = () => {
    return (
        <IonHeader id="item_header">
            <IonToolbar mode="md">
                <IonButtons slot="start" mode="md">
                    <IonBackButton text="" defaultHref="/" />
                </IonButtons>
                <IonTitle>McMenú Cheesy Buffalo</IonTitle>
                <IonButtons slot="end" mode="md">
                    <IonButton shape="round" color="light" fill="solid">
                        <IonIcon slot="icon-only" icon={shareOutline} />
                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>
    )
}

export default ItemHeader

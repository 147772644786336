import {Store} from 'pullstate';

const CartStore = new Store({
    items: []
})

export const addToCart = (item) => {
    CartStore.update(s => {
        s.items = [...s.items, item];
        console.log(s.items);
    })
}

export default CartStore;
import { IonImg } from '@ionic/react';
import { useHistory } from 'react-router';
import './StoreItem.scss';

const StoreItem = ({onClick = null, name, description, price, picture, id}) => {
    const history = useHistory();

    const goToItemScreen = () => {
        history.push(`store/${id}`);
    }

    return (
        <div id="store_item" onClick={goToItemScreen}>
            <div className="store_item_description">
                <h3>{name}</h3>
                <p>{description}</p>
                <span>GTQ{price}</span>
            </div>
            <div className="store_item_image">
                <IonImg src={picture}  />
            </div>
        </div>
    )
}

export default StoreItem

import { IonButton, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonNote, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { arrowForward, arrowForwardOutline, logoFacebook, logoGoogle, mail } from 'ionicons/icons';
import { useHistory } from 'react-router';
import WalaLogo from '../../components/common/wala-logo/WalaLogo';
import './LoginScreen.scss';

const LoginScreen = () => {

  const history = useHistory();

  const goToHome = () => {
    history.push('/home')
  }

  return (
    <IonPage id="login_screen">
      <IonContent fullscreen>
        <div className="login_screen_background">
          <div className="login_screen_box">
              <IonButton onClick={goToHome} className="login_screen_content_skip" color="light" mode="md" shape="round" fill="clear">
                SALTAR
                <IonIcon icon={arrowForward} slot="end" />
              </IonButton>
            <div className="login_screen_content">
              <WalaLogo color="light" size="extra_large" subtitle />

              <div  className="login_screen_action">
                <p>Introduzca su número de teléfono para continuar.</p>
                <form>
                  <IonItem mode="md" className="wala-input">
                  <p className="country_icon"><IonIcon src="assets/icon/guatemala.svg"/> (+502)</p>
                    <IonInput type="tel" placeholder="Número de teléfono" />
                  </IonItem>
                <IonButton routerLink="/login/session" shape="round" color="medium" mode="md" expand="full">
                  Continuar
                  <IonIcon icon={arrowForward} slot="end" />
                </IonButton>
                </form>
              </div>

            </div>
              {/* <span className="login_screen_privacy">
                Al continuar, acepta nuestros Términos y condiciones y nuestra Política de privacidad. Usamos sus datos para ofrecerle una experiencia personalizada y para comprender y mejorar nuestros servicios. Para obtener más información, consulte aquí.
              </span> */}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LoginScreen;

import { IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonNote } from '@ionic/react'
import './OrderProduct.scss';

const OrderProduct = ({name, total, quantity}) => {
    return (
        <IonItemSliding id="order_product">
        <IonItem>

            <IonNote color="dark" className="order_product_quantity" slot="start">{quantity}</IonNote>

            <div className="order_product_title">
            <h3>{name}</h3>
            <p>EXTRAS: Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </div>

            <IonNote color="dark" className="order_product_total" slot="end">GTQ{total}.00</IonNote>
        </IonItem>

        <IonItemOptions side="end">
            <IonItemOption color="danger" expandable>
                Eliminar
            </IonItemOption>
        </IonItemOptions>
    </IonItemSliding>
    )
}

export default OrderProduct

import { IonButton, IonInput, IonItem, IonModal } from '@ionic/react'
import HeaderCondense from '../../../../components/common/header-condense/HeaderCondense'
import Header from '../../../../components/common/header/Header'

const PromoModal = ({showModal = false, setShowModal}) => {
    return (
        
            <IonModal 
            isOpen={showModal} 
            cssClass='my-custom-class'
            mode="ios"
            swipeToClose={true}
            onDidDismiss={() => setShowModal(false)}>

                <Header className="transparent" closeButton onClick={() => setShowModal(false)} />
                <HeaderCondense title="¿Tienes código de promoción?"/>
                <form className="wala-content">

                <IonItem mode="md" className="wala-input">
                    <IonInput placeholder="Agrega código de promoción" />
                </IonItem>

                <IonButton onClick={() => setShowModal(false)} className="wala-button" shape="round" expand="full" color="success">Aplicar</IonButton>
                </form> 
            </IonModal>
    
    )
}

export default PromoModal

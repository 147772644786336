import { IonIcon, IonItem, IonLabel } from '@ionic/react';
import './ItemDetailIcon.scss';

const ItemDetailIcon = ({iconColor="dark", lines="inset", icon, detailIcon="none", title, subtitle, onClick=()=>{}}) => {
    return (
        <IonItem lines={lines} id="item_detail_icon" button detailIcon={detailIcon} onClick={onClick}>
            <IonIcon icon={icon} slot="start" color={iconColor}/>
            <IonLabel>
                <h3>{title}</h3>
                {subtitle && <p>{subtitle}</p> }
            </IonLabel>
        </IonItem>
    )
}

export default ItemDetailIcon

import { IonButton, IonFooter } from '@ionic/react';
import './MapModalFooter.scss';

const MapModalFooter = () => {
    return (
        <IonFooter id="map_modal_footer" mode="ios">
        <IonButton  mode="md" expand="full" shape="round" fill="solid" color="success">
            CONFIRMAR UBICACIÓN
        </IonButton>
    </IonFooter>
    )
}

export default MapModalFooter

import { IonContent, IonPage} from '@ionic/react';
import ResponsiveContainer from '../../components/layout/responsive-container/ResponsiveContainer';
import StoreCoverImage from './components/store-cover-image/StoreCoverImage';
import StoreHeaderInfo from './components/store-header-info/StoreHeaderInfo';
import StoreHeader from './components/store-header/StoreHeader';
import StoreSection from './components/store-section/StoreSection';

const StoreScreen = () => {
  return (
    <IonPage mode="ios">
      <StoreHeader />
      <IonContent fullscreen >
        <StoreCoverImage image="assets/imgs/store.webp" />
        <StoreHeaderInfo />
        <ResponsiveContainer>
        <StoreSection />    
        <StoreSection />        
        <StoreSection />        
          
          </ResponsiveContainer>        
      </IonContent>
    </IonPage>
  );
};

export default StoreScreen;

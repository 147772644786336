import { Redirect, Route, useHistory } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import './NavigationWithTabs.scss';

/* Screens */
import { tabs } from './tabs';
import StoreScreen from '../screens/store/StoreScreen';
import ItemScreen from '../screens/item/ItemScreen';
import OrderScreen from '../screens/order/OrderScreen';
import ViewCartButton from '../components/view-cart-button/ViewCartButton';
import CartBottomSheet from '../components/cart-bottom-sheet/CartBottomSheet';
import CheckoutScreen from '../screens/checkout/CheckoutScreen';
import LocationManagerScreen from '../screens/location-manager/LocationManagerScreen';
import PlaceScreen from '../screens/place/PlaceScreen';
import OrderStatusScreen from '../screens/order-status/OrderStatusScreen';

const NavigationWithTabs = () => {

    const history = useHistory();
    const [selectedTab, setSelectedTab] = useState(null);
    const [pathname, setPathname] = useState(null);
    const [hideTabBar, setHideTabBar] = useState(false);
    const tabsRef = useRef(null);

    const setInitialSelectedTab = () => {
        let {activeTab} = tabsRef.current.ionTabContextState;
        if(!activeTab) setHideTabBar(true);
        setSelectedTab(activeTab || 'home');
    }
    
    useEffect(() => {
        // show or hide tab bar based on location, I do it for screen navigation animation.
        return history.listen((location) => {
            setHideTabBar(!tabs.some(tab => tab.path === location.pathname));
            setPathname(location.pathname);
        })
    }, [history])

    useEffect(() => {
        setInitialSelectedTab();
    }, [tabsRef])

    return (
        <>
        <ViewCartButton pathname={pathname} position={hideTabBar ? 'down' : 'up'}/>
        <IonTabs ref={tabsRef} mode="md">
                <IonRouterOutlet>
                    {tabs.map(tab => (
                        <Route key={tab.tab} exact path={tab.path} render={(props) => (tab.screen(props))} />
                        ))}
                    <Route exact path="/store">
                        <StoreScreen />
                    </Route>
                    <Route exact path="/store/:id">
                        <ItemScreen />
                    </Route>
                    <Route exact path="/orders/order">
                        <OrderScreen />
                    </Route>
                    <Route exact path="/checkout">
                        <CheckoutScreen />
                    </Route>
                    <Route exact path="/status">
                        <OrderStatusScreen />
                    </Route>
                    <Route exact path="/location">
                        <LocationManagerScreen />
                    </Route>
                    <Route exact path="/location/place">
                        <PlaceScreen />
                    </Route>
                    <Route exact path="/">
                        <Redirect to="/login" />
                    </Route>
                </IonRouterOutlet>
                <IonTabBar className={hideTabBar && 'hide'} slot="bottom" onIonTabsDidChange={e => setSelectedTab(e.detail.tab)}>
                {tabs.map(tab => (
                    <IonTabButton key={tab.tab} tab={tab.tab} href={tab.path} >
                        <IonIcon icon={selectedTab === tab.tab ? tab.icon : tab.iconOutline} />
                        <IonLabel>{tab.label}</IonLabel>
                    </IonTabButton>
                ))}
            </IonTabBar>
            </IonTabs>
        </>
    )
}

export default NavigationWithTabs;

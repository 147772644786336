import './WalaLogo.scss';

const WalaLogo = ({size = "default", color = "success", subtitle=null}) => {
    return (
        <div id="wala-logo">
        <h3 className={`${size} ${color}`}>Walá!</h3>
        {subtitle && <p>.delivery</p>}
        </div>
    )
}

export default WalaLogo

import { heart, helpCircle, location } from 'ionicons/icons';

export const accountOptions = [
    {
        title: 'Favoritos',
        url: '/favs',
        icon: heart
    },
    {
        title: 'Lugares guardados',
        url: '/location',
        icon: location
    },
    {
        title: 'Centro de ayuda',
        url: '/help',
        icon: helpCircle
    }
]
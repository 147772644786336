import { IonButton, IonIcon, IonImg } from '@ionic/react';
import { heart, heartOutline } from 'ionicons/icons';
import { useState } from 'react';
import { useHistory } from 'react-router';
import './StoreCard.scss';

const StoreCard = ({name, rate, fee, time, img}) => {

    const [love, setLove] = useState(false);
    const history = useHistory();

    const goToStoreScreen = () => {
        history.push('/store');
      }

    return (
        <div id="store_card" onClick={goToStoreScreen}>
            <div className="store_card_image">
                <IonImg src={img} />
                <IonButton fill="clear" shape="round" onClick={e => {
                    e.stopPropagation();
                    setLove(!love);
                }}>
                    <IonIcon slot="icon-only" icon={love ? heart : heartOutline} color="light"  />
                </IonButton>
            </div>
            <div className="store_card_header">
                <div className="store_card_title">
                    <h3>{name}</h3>
                    <div className="store_card_rate">
                        <span>{rate}</span>
                    </div>
                </div>
                <div className="store_card_description">
                    <span className="store_card_fee">{fee}</span> 
                    <span>{time}</span>
                </div>
            </div>
        </div>
    )
}

export default StoreCard

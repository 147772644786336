import { IonContent, IonHeader, IonModal, IonPage } from '@ionic/react'
import { IonList, IonItem } from '@ionic/react';

import OrderProduct from '../../screens/order/components/order-product/OrderProduct';
import CartStore from '../../store/CartStore';
import HeaderCondense from '../common/header-condense/HeaderCondense';
import Header from '../common/header/Header';
import Spacer from '../common/spacer/Spacer';

import './CartBottomSheet.scss';
import CartBottomSheetFooter from './components/cart-bottom-sheet-footer/CartBottomSheetFooter';

const CartBottomSheet = ({showModal, setShowModal}) => {
    const items = CartStore.useState(s => s.items);

    return (
        <IonPage mode="ios">
            <IonContent>
                <IonModal
                    className="cart_bottom_sheet_content"
                    isOpen={showModal}
                    swipeToClose={true}
                    mode="ios"
                    initialBreakpoint={1}
                    breakpoints={[0, 1, 1]}
                    onDidDismiss={() => setShowModal(false)}>
                    <Header className="transparent" closeButton onClick={() => setShowModal(false)} />
                    <IonContent fullscreen>
                        <IonList>
                        <HeaderCondense title="Pollo Campero" />
                        {items.map(item => (
                            <OrderProduct key={item.id} {...item} />
                        ))}
                        <Spacer />
                        </IonList>
                <CartBottomSheetFooter setShowModal={setShowModal} />
                    </IonContent>
                </IonModal>
            </IonContent>
        </IonPage>
    )
}

export default CartBottomSheet

import { IonContent, IonPage } from "@ionic/react"
import Header from "../../components/common/header/Header"
import CodeStep from "./components/code-step/CodeStep"
import InfoStep from "./components/info-step/InfoStep"

const SessionScreen = () => {

    return (
        <IonPage mode="ios">
            <Header backButton defaultHref="/login" />
            <IonContent fullscreen>
                <CodeStep />
                {/* <InfoStep /> */}
            </IonContent>
        </IonPage>
    )
}

export default SessionScreen

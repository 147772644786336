import React, { useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker, DirectionsService, DirectionsRenderer } from '@react-google-maps/api';
import './Map.scss';
import { mapTheme } from './theme';

import vector from './me-marker-color.png';
import storeVector from './store-marker.png';

import { useGeolocation } from '../../hooks/useGeolocation';
import { IonButton } from '@ionic/react';
import { MAP_API } from '../../const/mapApi';
import MapModal from './components/MapModal';

const containerStyle = {
  width: '100%',
  height: '200px',
};

const options = {
  streetViewControl: false,
  mapTypeControl: false,
  styles: mapTheme.standar,
  draggable: false,
  zoomControl: false
}


const Map = ({origin=null, zoom=14, setButton=false}) => {

  const {coordinates} = useGeolocation();
  const [directions, setDirections] = useState(null);
  const [showModal, setShowModal] = useState(false);
  console.log(coordinates);

  const { isLoaded } = useJsApiLoader(MAP_API);

  const directionsCallback = (response) => {
    console.log('RESPONSE', response);
    if (response && !directions) {
      if (response.status === 'OK') {
        setDirections(response);
      } else {
        console.log('response: ', response)
      }
    }
  }

  return (
    <>
      {!isLoaded
        ? <span>Loading...</span>
        : <GoogleMap
          mapContainerStyle={containerStyle}
          center={{ lat: 14.7058314, lng: -91.8685998 }}
          zoom={zoom}
          options={options}
        >
          <Marker
            icon={{ url: vector }}
            position={{ lat: 14.7058314, lng: -91.8685998 }}
          />

          {origin && <Marker
            icon={{ url: storeVector }}
            position={origin}
          />}

         {(!directions && origin) && <DirectionsService
            options={{
              destination: { lat: 14.7058314, lng: -91.8685998 },
              origin,
              travelMode: 'DRIVING'
            }}
            callback={directionsCallback}
          />}

          {directions && <DirectionsRenderer
            options={{
              directions: directions,
              polylineOptions: {
                strokeColor: '#000000',
                strokeOpacity: 0.8,
                strokeWeight: 3
              },
              suppressMarkers: true
            }}
          />}
        </GoogleMap>
      }
      {setButton && <div className="map_set_pin">
        <IonButton onClick={() => setShowModal(true)}  mode="md" color="dark" shape="round">
            AJUSTAR PIN
        </IonButton>
      </div>}
      <MapModal showModal={showModal} setShowModal={setShowModal} />
    </>
  )

}

export default React.memo(Map);

import { IonButton, IonFooter } from '@ionic/react'
import { useHistory } from 'react-router';
import './CheckoutFooter.scss';

const CheckoutFooter = ({onClick, grandTotal}) => {

    const history = useHistory();

    const goToOrderStatusScreen = () => {
        history.push('/status');
    }

    return (
        <IonFooter id="checkout_footer" >
            <IonButton routerLink='/status' onClick={onClick} mode="md" expand="full" shape="round" fill="solid" color="success">
                CONFIRMAR • GTQ{grandTotal}
            </IonButton>
        </IonFooter>
    )
}

export default CheckoutFooter

import { IonButton } from '@ionic/react'
import './CategoryTitle.scss';

const CategoryTitle = ({title = "Título", onClick = null}) => {
    return (
        <div id="category_title">
            <h3>{title}</h3>
            {onClick && <IonButton mode="md" onClick={onClick} fill="clear">Ver más</IonButton>}
        </div>
    )
}

export default CategoryTitle

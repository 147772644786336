import './Summary.scss';

const Summary = () => {
    return (
        <div id="summary">
            <section>
                <p>Subtotal</p>
                <p>GTQ120.00</p>
            </section>
            <section>
                <p>Costo de envío</p>
                <p>GTQ12.00</p>
            </section>
            <section className="grandtotal">
                <p>Total</p>
                <p>GTQ132.00</p>
            </section>
        </div>
    )
}

export default Summary

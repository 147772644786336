import './OrderHeaderInfo.scss'

const OrderHeaderInfo = () => {
    return (
        <div id="order_header_info">
            <h3>Pollo Campero - Zona 1 9a. Calle</h3>
            <p>Ordern completada - <span>07/09/2021 a las 13:00</span></p>
        </div>
    )
}

export default OrderHeaderInfo

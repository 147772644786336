import { IonButton, IonIcon, IonImg } from '@ionic/react'
import { call, logoWhatsapp } from 'ionicons/icons'
import './OrderStatusAgent.scss';

const OrderStatusAgent = () => {
    const openWhatsapp = () => {
        window.open(`https://wa.me/5551234567` , '_blank');
      }

    return (
        <div id="order_status_agent">
            <IonImg src="assets/imgs/profile.png" alt="" />
            <p> <span>Josué Donis</span> se encargará de tu orden hoy.</p>
            <div>
                <IonButton href="tel:5551234567" mode="md" shape="round" color="dark">
                    <IonIcon icon={call} />
                </IonButton>
                <IonButton href="https://wa.me/5551234567" mode="md" shape="round" color="success">
                    <IonIcon icon={logoWhatsapp}/>
                </IonButton>
            </div>
        </div>
    )
}

export default OrderStatusAgent

import './BrowseContainer.scss';

const BrowseContainer = ({children}) => {
    return (
        <div id="browse_container">
            {children}
        </div>
    )
}

export default BrowseContainer

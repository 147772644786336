import { IonButton, IonIcon, IonItem, IonLabel } from '@ionic/react'
import { location, pencil } from 'ionicons/icons'
import { useHistory } from 'react-router';
import './PlaceItem.scss';

const PlaceItem = () => {

    const history = useHistory();

    const goToPlaceScreen = (e) => {
        e.stopPropagation();
        history.push('location/place');
    }

    return (
        <IonItem id="place_item" button detailIcon="none">
            <IonIcon icon={location} slot="start" color="dark"/>
            <IonLabel>
                <h3>Casa</h3>
                <p>Coatepeque, Quetzaltenango</p> 
            </IonLabel>
            <IonButton slot="end" shape="round" color="medium" onClick={goToPlaceScreen} >
                <IonIcon icon={pencil} slot="icon-only"  color="dark"/>
            </IonButton>

        </IonItem>
    )
}

export default PlaceItem

import { IonSearchbar } from '@ionic/react';
import { useState } from 'react';
import './SearchInput.scss';

const SearchInput = ({placeholder = "Buscar...", onIonChange = null, onIonFocus = null, searchText = ""}) => {
    return (
        <IonSearchbar id="search_input" placeholder={placeholder} mode="ios" value={searchText} onIonFocus={onIonFocus} onIonChange={onIonChange}></IonSearchbar>
    )
}

export default SearchInput;

import { IonCol, IonGrid, IonRow } from '@ionic/react'

const ResponsiveContainer = ({children, className="", size="12", sizeMd="10", offsetMd="1", sizeLg="10", offsetLg="1", sizeXl="10", offsetXl="1"}) => {
    return (
        <IonGrid>
            <IonRow>
                <IonCol size={size} size-md={sizeMd} offset-md={offsetMd} size-lg={sizeLg} offset-lg={offsetLg} size-xl={sizeXl} offset-xl={offsetXl}>
                    <IonRow className={className} >
                        {children}
                    </IonRow>
                </IonCol>
            </IonRow>
        </IonGrid>
    )
}

export default ResponsiveContainer

import React, { useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { mapTheme } from '../theme';

import vector from '../me-marker-color.png';
import { IonModal} from '@ionic/react';
import { MAP_API } from '../../../const/mapApi';
import Header from '../../common/header/Header';
import './MapModal.scss';
import HeaderCondense from '../../common/header-condense/HeaderCondense';
import MapModalFooter from './map-modal-footer/MapModalFooter';


const containerStyle = {
  width: '100%',
  height: '100vh',
  marginTop: '-56px'
};

const options = {
  streetViewControl: false,
  mapTypeControl: false,
  styles: mapTheme.standar
}


const MapModal = ({setShowModal, showModal}) => {

    const { isLoaded } = useJsApiLoader(MAP_API);
    const [map, setMap] = useState(null);

    const onLoad = React.useCallback(
        function onLoad (mapInstance) {
          // do something with map Instance
          setMap(mapInstance)
        }
      )

    return (
        <IonModal
        isOpen={showModal}
        className='map_modal'
        mode="ios"
        // presentingElement={router || undefined}
        onDidDismiss={() => setShowModal(false)}>
            <Header className="transparent" closeButton onClick={() => setShowModal(false)} />
          {!isLoaded
            ? <span>Loading...</span>
            : <GoogleMap
              mapContainerStyle={containerStyle}
              center={{ lat: 14.7058314, lng: -91.8685998 }}
              zoom={19}
              options={options}
              onLoad={onLoad}
              onBoundsChanged={() => map && console.log({lat: map.getCenter().lat(), lng: map.getCenter().lng()})}
            >
            <div className="map_marker_fixed">
                <img src={vector} />
            </div>

            </GoogleMap>
          }
            
          <MapModalFooter />
        </IonModal>
      )
}

export default MapModal

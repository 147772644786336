export const items = [
    {
        id: 1,
        name: 'Combo 8 Piezas + 2 Ensaladas de Repollo Gratis',
        picture: 'assets/imgs/item.jpg',
        description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas pariatur, iste, eaque fugit doloremque voluptatem veritatis officiis nisi aliquam deleniti sapiente consequuntur possimus accusantium quod dignissimos harum, accusamus itaque libero.',
        price: 12
    },
    {
        id: 2,
        name: 'Producto 2',
        picture: 'assets/imgs/item2.jpeg',
        description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. ',
        price: 194
    },
    {
        id: 3,
        name: 'Banquete de 20 Camperitos y 2 Aderezos por Q105',
        picture: 'assets/imgs/item4.jpeg',
        description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas pariatur, iste, eaque fugit doloremque voluptatem veritatis officiis nisi aliquam deleniti sapiente.',
        price: 20.50
    },
    {
        id: 4,
        name: 'Menú Súper Campero de Camperitos Doritos Flamin Hot',
        picture: 'assets/imgs/item2.jpeg',
        description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas pariatur, iste, eaque fugit doloremque voluptatem veritatis officiis nisi aliquam deleniti sapiente consequuntur possimus accusantium quod dignissimos harum, accusamus itaque libero.',
        price: 178
    }
]
import { IonButton, IonContent, IonPage } from '@ionic/react'
import { useEffect, useState } from 'react'
import Header from '../../components/common/header/Header'
import ResponsiveContainer from '../../components/layout/responsive-container/ResponsiveContainer'
import EstimatedArrival from './components/estimated-arrival/EstimatedArrival'
import OrderStatusContent from './components/order-status-content/OrderStatusContent'
import OrderStatusIndicator from './components/order-status-indicator/OrderStatusIndicator'

const ordesStatus = {
    1: 'ORDER_RECEIVED',
    2: 'BEING_PREPARED',
    3: 'ON_THE_WAY',
    4: 'DELIVERED'
}

const OrderStatusScreen = () => {
    
    const [status, setStatus] = useState('ORDER_RECEIVED'); 

    const autoplay = () => {
        const slideDelay = 8000;
        var count = 0;
        setInterval(()=>{
            count++;
            setStatus(ordesStatus[count])
          if (count === 4) {
            count = 0;
          }
        }, slideDelay);
    }

    useEffect(() => {
        autoplay();
    }, [])

    return (
      <IonPage>
        <Header backButton title="#ASD845">
          <IonButton mode="ios" slot="end" fill="clear">
            AYUDA
          </IonButton>
        </Header>
        <IonContent>
          <ResponsiveContainer className="wala-content">
            <EstimatedArrival />
            <OrderStatusIndicator status={status} />
            <OrderStatusContent status={status} />
          </ResponsiveContainer>
        </IonContent>
      </IonPage>
    )
}

export default OrderStatusScreen

import { IonButton, IonFooter, IonNote } from '@ionic/react';
import './ItemFooter.scss';

const ItemFooter = ({quantity, total, onClick}) => {
    return (
        <IonFooter id="item_footer">
            <IonButton onClick={onClick} mode="md" expand="full" shape="round" fill="solid" color="success">
                AGREGAR {quantity} A LA ORDEN
                <span>Q{total}</span>
            </IonButton>
        </IonFooter>
    )
}

export default ItemFooter

import { IonAccordionGroup, IonCol, IonContent, IonPage } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import AddRoundedButton from '../../components/common/add-rounded-button/AddRoundedButton';
import Spacer from '../../components/common/spacer/Spacer';
import ResponsiveContainer from '../../components/layout/responsive-container/ResponsiveContainer';
import { addToCart } from '../../store/CartStore';
import { items } from '../store/components/store-section/items-mock-data';
import ItemCoverImage from './components/item-cover-image/ItemCoverImage';
import ItemFooter from './components/item-footer/ItemFooter';
import ItemHeaderInfo from './components/item-header-info/ItemHeaderInfo';
import ItemHeader from './components/item-header/ItemHeader';
import ItemOptions from './components/item-options/ItemOptions';

const ItemScreen = () => {
    
    const history = useHistory();
    const { id } = useParams();
    const [item, setItem] = useState({})
    const [quantity, setQuantity] = useState(1);
    const [total, setTotal] = useState(0);
    
    useEffect(() => {
            let currentItem =  items.find(i => i.id == id);
            setItem(currentItem);   
            setTotal(quantity * currentItem.price);
    }, [id])

    const addItemToCart = () => {
        addToCart({...item, quantity, total, localId: `_${Math.random().toString(36).substr(2, 9)}`});
        history.goBack();
    }

    const checkboxList = [
        { val: 'Pepperoni', isChecked: false, fee: "1.00" },
        { val: 'Sausage', isChecked: false, fee: null },
        { val: 'Mushroom', isChecked: false, fee: "2.00" },
        { val: 'Salt', isChecked: false, fee: "1.00" },
        { val: 'Extra Cheese', isChecked: false, fee: null }
    ];
      

    return (
        <IonPage mode="ios">
            <ItemHeader />

            <IonContent fullscreen>

                <ResponsiveContainer>
                    <IonCol size="12" size-xl="6">
                        <ItemCoverImage image={item?.picture}/>
                    </IonCol>

                    <IonCol size="12" size-xl="6">

                        <ItemHeaderInfo {...item} />

                        <IonAccordionGroup multiple={true} value={['extras']} >
                            <ItemOptions elements={checkboxList} value="extras" />
                            <ItemOptions elements={checkboxList} value="extras" />

                            <ItemOptions elements={checkboxList} value="extras" />

                        </IonAccordionGroup>
                        <Spacer height={32} />
                        <AddRoundedButton
                        value={quantity}
                        onChange={ (value) => {
                            setTotal(value * item.price);
                            setQuantity(value);  
                        }}
                        />
                    </IonCol>

                 </ResponsiveContainer>

            </IonContent>

           <ItemFooter 
           quantity={quantity} 
           total={total} 
           onClick={addItemToCart}/>
        </IonPage>
    )
}

export default ItemScreen

import { IonButton, IonIcon } from '@ionic/react';
import { addOutline, removeOutline } from 'ionicons/icons';
import { useState } from 'react';
import './AddRoundedButton.scss';

const AddRoundedButton = ({value=0, className = "default", onChange, min = 1, max = null}) => {

    const [quantity, setQuantity] = useState(value);

    const addItem = () => {
        setQuantity(quantity + 1);
        onChange(quantity + 1);
    }

    const removeItem = () => {
        if(quantity === min) return
        setQuantity(quantity - 1);
        onChange(quantity - 1);
    }

    return (
        <div id="add_rounded_button" className={className}>
           {quantity > 0 && 
           <> 
            <IonButton disabled={quantity < 1} shape="round" mode="md" fill="solid" color="medium" onClick={removeItem}>
                <IonIcon slot="icon-only" icon={removeOutline} />
            </IonButton>
            <p>{quantity}</p>
           </>
           }
            <IonButton shape="round" fill="solid" mode="md" onClick={addItem}>
                <IonIcon slot="icon-only" icon={addOutline} className="add_rounded_button_add" />
            </IonButton>
        </div>
    )
}

export default AddRoundedButton

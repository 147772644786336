import { IonButton, IonFooter, useIonModal } from '@ionic/react'
import { useEffect, useState } from 'react';
import CartStore from '../../store/CartStore';
import CartBottomSheet from '../cart-bottom-sheet/CartBottomSheet';
import './ViewCartButton.scss';

const paths = ['/home', '/browse', '/store', '/acount', '/orders'];

const ViewCartButton = ({pathname, position}) => {

    const items = CartStore.useState(s => s.items);
    const [hideButton, setHideButton] = useState(false);
    const [showModal, setShowModal] = useState(false);
    useEffect(() => {
        setHideButton(paths.find(p => p === pathname));
    }, [pathname]);
 
    return (
        // 
        <>
        {(hideButton && items.length) && <IonFooter id="view_cart_footer" mode="ios" className={position}>
            <IonButton onClick={() => setShowModal(true)} mode="md" expand="full" shape="round" fill="solid" color="success">
                VER MI ORDEN ({items.length || 0})
            </IonButton>
        </IonFooter>}
        <CartBottomSheet showModal={showModal} setShowModal={setShowModal}/>
        </>
    )
}

export default ViewCartButton

export const banners = [
    {
        image: 'assets/imgs/banner.png',
        title: 'Summer Savings ',
        description: 'Get a free item with your order, but only through Aug 25. ',
        background: '#8dc8e8'
    },
    {
        image: 'assets/imgs/banner2.png',
        title: 'No Delivery Fee + 5% Off—Only for Unlimited Members',
        description: '',
        background: '#0000ff',
        color: '#ffffff'
    },
    {
        image: 'assets/imgs/banner3.png',
        title: '50% off this Tocaya bowl',
        description: 'The Fajita Del Rey is everyone’s favorite.',
        background: '#8dc8e8'
    }
]


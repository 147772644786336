import { IonBackButton, IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar } from '@ionic/react';
import { ellipsisHorizontal, heartOutline } from 'ionicons/icons';
import './StoreHeader.scss';

const StoreHeader = () => {
    return (
        <IonHeader id="store_header">
            <IonToolbar mode="md">
                <IonButtons slot="start" mode="md">
                    <IonBackButton text="" defaultHref="/" />
                </IonButtons>
                <IonTitle>McDonald's</IonTitle>
                <IonButtons slot="end" mode="md">
                    <IonButton shape="round" color="light" fill="solid">
                        <IonIcon slot="icon-only" icon={heartOutline} />
                    </IonButton>
                    <IonButton shape="round" color="light" fill="solid">
                        <IonIcon slot="icon-only" icon={ellipsisHorizontal} />
                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>
    )
}

export default StoreHeader

import { IonApp } from '@ionic/react';
/* Global scss variables */
import './theme/global.scss';
import WalaApp from './navigation/WalaApp';

const App: React.FC = () => {
  
  return (
    <IonApp>
        <WalaApp />
    </IonApp>
  )
};

export default App;

import { IonHeader } from '@ionic/react';
import './HomeHeader.scss';

const HomeHeader = ({children}) => {
    return (
        <IonHeader id="home_header" mode="ios">
            {children}
        </IonHeader>
    )
}

export default HomeHeader

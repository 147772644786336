import { IonImg } from '@ionic/react';
import './StoreCoverImage.scss';

const StoreCoverImage = ({image}) => {
    return (
        <div id="store_cover_image">
            <IonImg  src={image} />
        </div>
    )
}

export default StoreCoverImage

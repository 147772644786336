import { useIonViewWillEnter } from '@ionic/react';
import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import StoreCard from '../../../../components/store-card/StoreCard';
import { stores } from '../../stores-mock-data';

const swiperProperties = {
    slidesPerView:1.2,
    freeMode: true,
    breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1.2,
          spaceBetween: 4
        },
        // when window width is >= 480px
        640: {
          slidesPerView: 2.1,
          spaceBetween: 4
        },
        // when window width is >= 640px
        992: {
          slidesPerView: 3.1,
          spaceBetween: 4
        }
    }
}

const StoreCarousel = () => {

    const [ spaceBetween, setSpaceBetween ] = useState(0);
    useIonViewWillEnter(() => {
		setSpaceBetween(10);
	});

    return (
        <Swiper
        spaceBetween={spaceBetween}
        {...swiperProperties}>
            {stores.map(store => (
            <SwiperSlide key={store.id}>
                <StoreCard key={store.id} {...store}/>
            </SwiperSlide>
            ))}        
      </Swiper>
    )
}

export default StoreCarousel;

import { IonButton, IonIcon, IonInput, IonItem } from '@ionic/react';
import { arrowForward } from 'ionicons/icons';
import HeaderCondense from '../../../../components/common/header-condense/HeaderCondense'
import ResponsiveContainer from '../../../../components/layout/responsive-container/ResponsiveContainer'

const CodeStep = () => {
    return (
        <>
            <HeaderCondense title="Verficar código" />
            <ResponsiveContainer className="wala-content">
                <p>Ingrese el código de 4 dígitos que se le envió al (+502) <b>4545 4545</b> </p>
                <form>
                    <IonItem mode="md" className="wala-input">
                        <IonInput type="tel" placeholder="0000" />
                    </IonItem>
                    <IonButton mode="md" className="wala-button" shape="round" color="success" expand="full">
                        Siguiente
                        <IonIcon slot="end" icon={arrowForward} />
                    </IonButton>
                </form>
            </ResponsiveContainer>
        </>
    )
}

export default CodeStep

import { IonButton, IonContent, IonInput, IonItem, IonLabel, IonNote, IonPage, IonTextarea } from '@ionic/react';
import HeaderCondense from '../../components/common/header-condense/HeaderCondense';
import Header from '../../components/common/header/Header';
import Spacer from '../../components/common/spacer/Spacer';
import ResponsiveContainer from '../../components/layout/responsive-container/ResponsiveContainer';
import Map from '../../components/map/Map';

const PlaceScreen = () => {
    return (
        <IonPage mode="ios">
        <Header backButton={true} title="Detalles de la entrega" />
        <IonContent fullscreen>
            <HeaderCondense title="Detalles de la entrega" />
            <ResponsiveContainer>   
            <Map setButton={true} zoom={18} />
            <form className="wala-content">
            <IonItem mode="md" className="wala-input">
                <IonInput placeholder="Dirección completa"><IonNote slot="end" className="required">*</IonNote></IonInput>
            </IonItem>
            <IonItem mode="md" className="wala-input">
                <IonInput placeholder="Lugar de referencia"/>
            </IonItem>
            <IonItem mode="md" className="wala-input">
                <IonTextarea rows="4" placeholder="Agregar instrucciones de entrega"/>
            </IonItem>
            
            <IonItem mode="md" className="wala-input">
                <IonLabel position="stacked">¿Guardar esta dirección?</IonLabel>
                <IonInput placeholder="Agregar etiqueta (ej. Casa)"><IonNote slot="end" className="required">*</IonNote></IonInput>
            </IonItem>
            <Spacer height={32} />
            <IonButton color="success" expand="full" mode="md" className="wala-button" shape="round">
                GUARDAR
            </IonButton>
            <Spacer height={32} />

            </form>
            </ResponsiveContainer>
        </IonContent>
        
    </IonPage>
    )
}

export default PlaceScreen

import { IonCol } from "@ionic/react"
import StoreCard from "../../../../components/store-card/StoreCard"
import { stores } from "../../stores-mock-data"

const StoreList = () => {
    
    return (
        <>
        {stores.map(store => (
            <IonCol key={store.id} size="12" size-md="4" size-xl="3">
                <StoreCard key={store.id} {...store}/>
            </IonCol>
        ))}
        </>
    )
}

export default StoreList

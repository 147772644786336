import { IonAccordion, IonCheckbox, IonItem, IonLabel, IonList, IonNote } from "@ionic/react"
import { chevronDownCircleOutline } from "ionicons/icons";
import AddRoundedButton from "../../../../components/common/add-rounded-button/AddRoundedButton";
import './ItemOptions.scss';

const ItemOptions = ({value, elements, type}) => {
    return (
        <IonAccordion id="item_option" value={value} toggleIcon={chevronDownCircleOutline}>
        <IonItem className="item_option_header" slot="header" lines="none">
            <div className="item_option_title">
                <h3>Extras</h3>
                <p>Obligatorio - Elige 2</p>
            </div>
        </IonItem>
        <IonList slot="content" className="item_option_list">
        {elements.map(({ val, isChecked, fee }, i) => (
            <IonItem key={i} lines="full">
                {/* <AddRoundedButton
                className="small"
                min={0}
                onAdd={value => console.log(value)}
                onRemove={value => console.log(value)}
                value={0}/> */}
                <IonLabel>{val}</IonLabel>
                <IonCheckbox mode="ios" slot="start" color="success" value={val} checked={isChecked} />
                {fee && <IonNote slot="end">+{fee}</IonNote>}
            </IonItem>
        ))}
        </IonList>
    </IonAccordion>
    )
}

export default ItemOptions

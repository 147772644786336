import { IonButton, IonIcon } from '@ionic/react'
import { add } from 'ionicons/icons'
import './SectionTitle.scss';

const SectionTitle = ({title, subtitle, button}) => {
    return (
        <div id="section_title">
            <h3>{title}</h3>
            {subtitle && <p>{subtitle}</p>}
            {button && <IonButton shape="round" fill="clear" color="success">
               {button}
            </IonButton>}
        </div>
    )
}

export default SectionTitle

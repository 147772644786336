export const categories = [
    {
        id: 1,
        img: 'assets/imgs/sushi.svg',
        name: 'Sushi'
    },
    {
        id: 2,
        img: 'assets/imgs/hamburguer.svg',
        name: 'Hamburguer'
    },
    {
        id: 3,
        img: 'assets/imgs/green-salad.svg',
        name: 'Green Salad'
    },
    {
        id: 4,
        img: 'assets/imgs/doughnut.svg',
        name: 'Doughnut'
    },
    {
        id: 5,
        img: 'assets/imgs/taco.svg',
        name: 'Taco'
    },
    {
        id: 6,
        img: 'assets/imgs/cooked-rice.svg',
        name: 'Cooked Rice'
    },
    {
        id: 7,
        img: 'assets/imgs/sushi.svg',
        name: 'Sushi'
    },
    {
        id: 8,
        img: 'assets/imgs/hamburguer.svg',
        name: 'Hamburguer'
    },
    {
        id: 9,
        img: 'assets/imgs/green-salad.svg',
        name: 'Green Salad'
    },
    {
        id: 10,
        img: 'assets/imgs/doughnut.svg',
        name: 'Doughnut'
    },
    {
        id: 11,
        img: 'assets/imgs/taco.svg',
        name: 'Taco'
    },
    {
        id: 12,
        img: 'assets/imgs/cooked-rice.svg',
        name: 'Cooked Rice'
    }

]
import { Geolocation } from '@capacitor/geolocation';
import { useEffect, useState } from "react";

const positionOptions = {
    enableHighAccuracy: true,
    maximumAge: 2
}

export const useGeolocation = () => {

    const [coordinates, setCoordinates] = useState(null);

    useEffect(() => {
        getCurrentPosition();
    }, [])

    const getCurrentPosition = async () => {
        const {location} = await Geolocation.checkPermissions();
        console.log(location);
        try {
            if(location === 'denied') console.log('denied');
                const {coords} = await Geolocation.getCurrentPosition(positionOptions);
                setCoordinates({lat: coords.latitude, lng: coords.longitude})
        } catch (error) {
            console.log('User denied Geolocation', error);
        } 
    };
    return {
        coordinates
    }

}
import { IonItem } from '@ionic/react';
import { useState } from 'react';
import './OptionSelect.scss';


const OptionSelect = ({className="row", values=[], onChange=()=>{}}) => {
    const [items, setItems] = useState(values);

    const setSelected = (index) => {
        setItems(items.map((item, i) => {
            item.selected = index === i;
            return item;
        }))
        onChange(items[index]);
    }

    return (
        <div id="option_select" className={className}>
            {items.map((item, index) =>(
            <IonItem key={index} onClick={() => setSelected(index)} button lines="none" detail={false} mode="md" className={`option ${item.selected && 'selected'}`}>
                <p>{item.label}</p>
            </IonItem >
            ))}
        </div>
    )
}

export default OptionSelect

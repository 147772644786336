import { IonButton, IonInput, IonItem, IonModal } from '@ionic/react'
import HeaderCondense from '../../../../components/common/header-condense/HeaderCondense'
import Header from '../../../../components/common/header/Header'

const DeliveryTimeModal = ({showModal = false, setShowModal}) => {
    return (
        
            <IonModal 
            isOpen={showModal} 
            cssClass='my-custom-class'
            mode="ios"
            swipeToClose={true}
            onDidDismiss={() => setShowModal(false)}>

                <Header className="transparent" closeButton onClick={() => setShowModal(false)} />
                <HeaderCondense title="Elige una hora"/>
                <form className="wala-content">

                
                <IonButton onClick={() => setShowModal(false)} className="wala-button" shape="round" expand="full" color="success">Programar</IonButton>
                <IonButton onClick={() => setShowModal(false)} className="wala-button" shape="round" expand="full" color="medium">Entregar ahora</IonButton>
                </form> 
            </IonModal>
    
    )
}

export default DeliveryTimeModal

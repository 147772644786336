import { useState } from "react";
import { ViewStates } from "../const/viewStates";
import { SubcategoriesService } from "../graphql/subcategories/service";

const initialState = {
    data: [],
    viewState: ViewStates.INITIAL,
}

export const useSubcategories = () => {
    const [state, setState] = useState(initialState);

    
    const loadSubcategories = async() => {
        try {

            setState({
                viewState: ViewStates.LOADING,
            });            

            const {subcategories:data} = await SubcategoriesService.getAll({
                where: {
                  categoryId: "01fe23db-3de8-49ad-8a2c-8132d7c96dcd"
                }
            });
                        
            setState({
                data,
                viewState: data.length ? ViewStates.CONTENT : ViewStates.EMPTY
            });
            
        } catch (error) {
            // console.log(JSON.parse(JSON.stringify(error)));
            setState({
                viewState: ViewStates.ERROR,
            });
        }
    }

    return {
        state,
        loadSubcategories
    }
}

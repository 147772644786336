import { Route } from "react-router-dom"
import LoginScreen from "../screens/login/LoginScreen"
import SessionScreen from "../screens/session/SessionScreen"

const PublicNavigation = () => {
  return (
      <>
        <Route path="/login" component={LoginScreen} exact={true} />
        <Route path="/login/session" component={SessionScreen} exact={true} />

      </>
  )
}

export default PublicNavigation

import { useIonViewWillEnter } from '@ionic/react';
import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import CategoryItemSkeleton from '../../../../components/skeleton/category-item-skeleton/CategoryItemSkeleton';
import { ViewStates } from '../../../../const/viewStates';
import { useSubcategories } from '../../../../hooks/useSubcategories';
import CategoryItem from "../category-item/CategoryItem"

const swiperProperties = {
    slidesPerView: 4.6,
    breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 4.6,
          spaceBetween: 8
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 8,
          spaceBetween: 10
        },
        // when window width is >= 800px
        992: {
          slidesPerView: 12,
          spaceBetween: 50
        }
    }
}

const CategoryItemCarousel = () => {

    const [ spaceBetween, setSpaceBetween ] = useState(0);

    const {state, loadSubcategories} = useSubcategories();
    
     // Fake items for the skeleton items
    const fakeArray = Array.from(Array(16).keys());

    useIonViewWillEnter(() => {
		  setSpaceBetween(8);
	  });

    useEffect(() => {
      loadSubcategories();
    }, [])

  return (
    
    <Swiper
      spaceBetween={spaceBetween}
      {...swiperProperties}>

      {state.viewState === ViewStates.LOADING && fakeArray.map(i => (
        <SwiperSlide key={i}>
          <CategoryItemSkeleton key={i} />
        </SwiperSlide>
      ))}

      {state.viewState === ViewStates.CONTENT && state.data.map(subcategory => (
        <SwiperSlide key={subcategory.id}>
          <CategoryItem key={subcategory.id} {...subcategory} />
        </SwiperSlide>
      ))}

    </Swiper>

 
  
  )
}

export default CategoryItemCarousel;

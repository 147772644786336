import { IonReactRouter } from "@ionic/react-router";
import ViewCartButton from "../components/view-cart-button/ViewCartButton";
import NavigationWithTabs from "./NavigationWithTabs";
import PublicNavigation from "./PublicNavigation";

const WalaApp = () => {
    return (
        <IonReactRouter>
            <NavigationWithTabs />
            <PublicNavigation />
        </IonReactRouter>
    )
}

export default WalaApp

import { IonHeader, IonTitle, IonToolbar } from '@ionic/react'
import './HeaderCondense.scss';

const HeaderCondense = ({title}) => {
    return (
        <IonHeader collapse="condense" id="header_condense">
        <IonToolbar>
          {/* <IonTitle size="large">{title}</IonTitle> */}
          <h3>{title}</h3>
        </IonToolbar>
      </IonHeader>
    )
}

export default HeaderCondense

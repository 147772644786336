import { orderStatus } from '../../../../const/orderStatus';
import OrderStatusAgent from '../order-status-agent/OrderStatusAgent';
import './OrderStatusContent.scss';

const OrderStatusContent = ({status}) => {
    return (
        <div id="order_status_content" className="wala-content">
            {
                status === orderStatus.ORDER_RECEIVED &&
                <>
                    <h3>Orden recibida. Confirmando el pedido con el restaurante.</h3>
                    <img src="assets/imgs/ORDER_RECEIVED.gif" alt="" />
                </>
            }
            {
                status === orderStatus.BEING_PREPARED &&
                <>
                    <h3>Orden confirmada. La comida está en preparación</h3>
                    <img src="assets/imgs/BEING_PREPARED.gif" alt="" />
                </>
            }

            {
                 status === orderStatus.ON_THE_WAY &&
                <>
                    <h3>El repartidor está en camino. Recibirás una alerta cuando esté en tu puerta.</h3>
                    <OrderStatusAgent />
                </>
            }

            {
                status === orderStatus.DELIVERED && 
                <>
                    <h3>La comida esta llegando. ¡A comer!</h3>
                    <img src="assets/imgs/DELIVERED.gif" alt="" />
                </>
            }
        </div>
    )
}

export default OrderStatusContent

import './EstimatedArrival.scss';

const EstimatedArrival = () => {
    return (
        <div id="estimated_arrival">
            <h3>6:00 PM</h3>
            <p>Hora estimada de entrega</p>
        </div>
    )
}

export default EstimatedArrival

import { IonButton, IonIcon, IonImg } from '@ionic/react';
import { arrowForwardOutline } from 'ionicons/icons';
import './PromotionalBanner.scss';

const PromotionalBanner = ({image, title, description, background = "#f4f4f4", color = "#000000"}) => {
    return (
        <div id="promotional_banner" style={{background}}>
            <div className="promotional_banner_content" style={{color}}>
                <h3>{title}</h3>
                <p>{description}</p>
                <IonButton shape="round" size="small" mode="ios" color="dark" fill="solid">
                    Ordenar
                    <IonIcon icon={arrowForwardOutline} slot="end" />
                </IonButton>
            </div>
            <IonImg src={image} />
        </div>
    )
}

export default PromotionalBanner

import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import SearchInput from '../../components/common/search-input/SearchInput';
import { categories } from '../home/components/category-item-carousel/mock-data';
import CategoryItem from '../home/components/category-item/CategoryItem';
import BrowseContainer from './components/browse-container/BrowseContainer';

const BrowseScreen = () => {
  return (
    <IonPage>
      <IonHeader mode="ios" style={{background: 'white'}} >
        <SearchInput
        placeholder="Restaurantes, platos, cocina"
      />
      </IonHeader>
      <IonContent fullscreen>
        <BrowseContainer>
        {categories.map(category => (
          <CategoryItem key={category.id} {...category} className="large" />
          ))}
        </BrowseContainer>
      </IonContent>
    </IonPage>
  );
};

export default BrowseScreen;

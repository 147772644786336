import { bagHandle, bagHandleOutline, home, homeOutline, personCircle, personCircleOutline, search, searchOutline } from "ionicons/icons";
import AccountScreen from "../screens/account/AccountScreen";
import BrowseScreen from "../screens/browse/BrowseScreen";
import HomeScreen from "../screens/home/HomeScreen";
import OrdersScreen from "../screens/orders/OrdersScreen";

export const tabs = [{
        label: 'Inicio',
        path: '/home',
        tab: 'home',
        icon: home,
        iconOutline: homeOutline,
        screen: (props) => < HomeScreen {...props}/>    
    },
    {
        label: 'Explorar',
        path: '/browse',
        tab: 'browse',
        icon: search,
        iconOutline: searchOutline,
        screen: (props) => < BrowseScreen {...props}/>    
    },
    {
        label: 'Ordenes',
        path: '/orders',
        tab: 'orders',
        icon: bagHandle,
        iconOutline: bagHandleOutline,
        screen: (props) => < OrdersScreen {...props}/>    
    },
    {
        label: 'Perfíl',
        path: '/account',
        tab: 'account',
        icon: personCircle,
        iconOutline: personCircleOutline,
        screen: (props) => < AccountScreen {...props}/>    
    },
]
export const stores = [
    {
        id: 1,
        name: 'McDonalds Coatepeque',
        fee: 'Costo de envio: Q15.00',
        time: '20-30 min',
        rate: 4.2,
        img: 'assets/imgs/store.webp'
    },
    {
        id: 2,
        name: 'TacoBell Coatepeque',
        fee: 'Costo de envio: Q14.00',
        time: '20-30 min',
        rate: 3.5,
        img: 'assets/imgs/tacobell.webp'
    },
    {
        id: 3,
        name: 'Pollo Campero Coatepeque',
        fee: 'Costo de envio: Q10.00',
        time: '20-30 min',
        rate: 5,
        img: 'assets/imgs/pollocampero.webp'
    },
    {
        id: 4,
        name: 'McDonalds Coatepeque',
        fee: 'Costo de envio: Q15.00',
        time: '20-30 min',
        rate: 4.2,
        img: 'assets/imgs/store.webp'
    },
    {
        id: 5,
        name: 'TacoBell Coatepeque',
        fee: 'Costo de envio: Q10.00',
        time: '20-30 min',
        rate: 3,
        img: 'assets/imgs/tacobell.webp'
    }
]
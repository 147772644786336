import { IonCol, IonItemDivider, IonLabel } from '@ionic/react';
import { useHistory } from 'react-router';
import CategoryTitle from '../../../../components/common/category-title/CategoryTitle';
import StoreItem from '../store-item/StoreItem';
import { items } from './items-mock-data';
import './StoreSection.scss';

const StoreSection = () => {

    return (
        <>
            <IonItemDivider id="store_section" sticky="true" mode="ios">
                <CategoryTitle title="Section" />
            </IonItemDivider>
        
            {items.map(item => (
                <IonCol size="12" size-md="4" size-xl="4">
                    <StoreItem key={item.id} {...item} />
                </IonCol>  
            ))}
        </>
    )
}

export default StoreSection

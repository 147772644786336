import { IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import './StoreHeaderInfo.scss';

const StoreHeaderInfo = () => {
    return (
        <div id="store_header_info">
            <IonHeader  className="store_header_info_title" collapse="condense">
                <IonToolbar>
                    <IonTitle size="large">McDonald's</IonTitle>
                </IonToolbar>
            </IonHeader>
            <div className="store_header_info_description">
            <p>American - Fast Food - Burgers</p>
            <p>25 - 35 min - Delivery - 4.7 ⭐ (300) - Q12.00 Fee</p>
            </div>
        </div>
    )
}

export default StoreHeaderInfo
